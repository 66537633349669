<template>
  <div class='base-quantity-input'>
    <span v-if="isRemoveBtnVisible" class="remove-handler" @click="changeInput('remove')">{{ $t('general.cartModule.removeItem') }}</span>
    <span v-else-if="hasRemoveHandler ? localeInput > 0 : true" class="input-handler decrease-handler" @click="changeInput('decrease')">-</span>
    <input type="number" v-model="localeInput">
    <span class="input-handler increase-handler" @click="changeInput('increase')">+</span>
  </div>
</template>

<script>
export default {
  name: 'BaseQuantityInput',
  props: {
    defaultValue: {
      type: Number,
      required: false,
    },
    minValue: {
      type: Number,
      required: false,
      default: undefined,
    },
    hasRemoveHandler: {
      type: Boolean,
      required: false,
      default: false,
    },
    disableInput: {
      type: Boolean,
      required: false,
      default: false,
    },
    id: {
      type: String,
      required: true,
    },
  },
  components: {},
  data: () => ({
    localeInput: 0,
    isRemoveBtnVisible: false,
    lastInputVale: undefined,
  }),
  created() {
    this.$eventBus.onResetCart(this, (payload) => {
      if (payload.id === this.id) {
        this.localeInput = this.lastInputVale;
      }
    });
    if (this.defaultValue) {
      this.localeInput = this.defaultValue;
      this.lastInputVale = this.defaultValue;
    }
  },
  computed: {},
  watch: {
    defaultValue(newVal) {
      this.localeInput = newVal;
    },
    localeInput(val) {
      let parsedVal = 0;

      if (val === '') {
        parsedVal = 0;
        this.localeInput = 0;
      } else {
        parsedVal = parseInt(val, 10);
      }

      if (this.minValue !== undefined) {
        if (parsedVal >= this.minValue) {
          this.$emit('changed', parsedVal);
          if (parsedVal > this.minValue && this.isRemoveBtnVisible) {
            this.isRemoveBtnVisible = false;
          }
        } else {
          this.localeInput = 1;
        }
      } else if (parsedVal >= 0) {
        this.$emit('changed', parsedVal);
      } else {
        this.localeInput = -1 * parsedVal;
        this.$emit('changed', -1 * parsedVal);
      }
    },
  },
  methods: {
    changeInput(action) {
      if (this.disableInput) {
        return;
      }
      switch (action) {
        case 'decrease':
          if (this.localeInput - 1 >= 0) {
            if (this.minValue !== undefined) {
              if (this.localeInput - 1 >= this.minValue) {
                this.localeInput -= 1;
              } else if (this.localeInput - 1 === 0 && this.hasRemoveHandler) {
                this.isRemoveBtnVisible = true;
              }
            } else {
              this.localeInput -= 1;
            }
          }
          break;
        case 'increase':
          this.localeInput += 1;
          if (this.isRemoveBtnVisible && this.hasRemoveHandler) {
            this.isRemoveBtnVisible = false;
          }
          break;
        case 'remove':
          this.$emit('removeClicked');
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang='scss' scoped>
$inputSize: 20px;
$mobileInputSize: 30px;

.base-quantity-input {
  user-select: none;
  width: 110px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
}

input {
  appearance: none;
  border: 0;
  background-color: $quantityInputBackground;
  border-radius: $globalBorderRadius / 2;
  width: $inputSize;
  height: $inputSize;
  -moz-appearance: textfield;
  font-size: 15px;
  font-weight: 500;
  color: $globalFontColor;
  text-align: center;
  transition: $transitionBase;
  margin: 0 9px;

  @media screen and (max-width: $breakpointDownSm) {
    width: $mobileInputSize;
    height: $mobileInputSize;
    font-size: 16px;
  }

  &:focus {
    outline: none;
    border: 2px solid $primaryBlue;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.remove-handler {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 11px;
  color: $globalRedColor;
  cursor: pointer;
}

.input-handler {
  font-size: 20px;
  font-weight: 500;
  line-height: $inputSize;
  cursor: pointer;
  transition: $transitionBase;

  @media screen and (max-width: $breakpointDownSm) {
    font-size: 24px;
    line-height: $mobileInputSize;
  }

  &:hover {
    color: $primaryBlue;
  }
}

</style>

<template>
  <div class='cart-module' :class="{ 'has-error': toast.isVisible }">
    <error-toast
      :isVisible="toast.isVisible"
      :text="toast.text"
      :type="toast.type"
    />
    <div v-if="isCartEmpty" class="empty-state">
      <div class="icon-wrapper"><icon-line-cart /></div>
      <span class="title">{{ $t('general.cartModule.emptyState.title') }}</span>
      <span class="subtitle">{{ $t('general.cartModule.emptyState.subtitle') }}</span>
    </div>
    <div v-else class="cart-content" :class="[{ 'animate__animated animate__pulse': isAnimating }]">
      <span v-if="isTitleVisible" class="cart-title">{{ $t(titleKey) }}</span>
      <div v-if="sortedCartItems"
        class="cart-items" :class="{ 'scrollable' : hasMaxHeight }" >
        <cart-items-group
          v-for="(cartItem, index) in sortedCartItems" :key="index"
          :id="index.toString()"
          :disableInput="disableInput"
          :date="cartItem.date"
          :items="cartItem.cikkList"
          :hasRemove="hasRemove"
          @quantityChanged="handleQuantityChange"
          @deleteClicked="handleItemDeletion"
          @quickDeleteClicked="handleQuickDeletion"
        />
      </div>

      <div class="cart-footer">
        <div class="summary-row">
          <span class="title">{{ $t('general.cartModule.summary.title') }}</span>
          <span class="value">{{ this.getCurrentCart.price.grossUnitPrice | toCurrency }}</span>
        </div>
        <div class="button-row" v-if="!isBtnHidden">
          <base-button :isLoading="isButtonLoading" :isSecondary="true" :text="$t(buttonKey)" @clicked="handleButtonClick"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import BaseButton from '@/components/shared/elements/buttons/ButtonBase';
import CartItemsGroup from '@/components/shared/modules/carts/CartItemsGroup';

export default {
  name: 'Cart',
  props: {
    titleKey: {
      type: String,
      required: false,
      default: 'general.cartModule.title',
    },
    isTitleVisible: {
      type: Boolean,
      required: false,
      default: true,
    },
    animateOnChange: {
      type: Boolean,
      required: false,
      default: true,
    },
    buttonKey: {
      type: String,
      required: false,
      default: 'general.cartModule.summary.btnText',
    },
    hasRemove: {
      type: Boolean,
      required: false,
      default: false,
    },
    isInModifyState: {
      type: Boolean,
      required: false,
      default: false,
    },
    isBtnHidden: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasMaxHeight: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    'icon-line-cart': () => import('@/components/shared/elements/icons/IconLineCart'),
    BaseButton,
    CartItemsGroup,
    'error-toast': () => import('@/components/shared/elements/toasts/ErrorToast'),
  },
  data: () => ({
    isAnimating: false,
    modifications: [],
    deletions: [],
    isButtonLoading: false,
    tempCart: undefined,
    toast: {
      isVisible: false,
      type: 'error',
      text: undefined,
    },
    disableInput: false,
  }),
  created() {
    if (this.isInModifyState && this.getCurrentCart !== undefined) {
      this.tempCart = JSON.parse(JSON.stringify(this.getCurrentCart));
    }
  },
  computed: {
    ...mapGetters({
      getCurrentCart: 'cart/getCurrentCart',
    }),
    computedCart() {
      if (this.isInModifyState && this.getCurrentCart !== undefined) {
        return this.tempCart;
      }
      return this.getCurrentCart;
    },
    sortedCartItems() {
      let result = [];
      if (this.computedCart.cartItems) {
        result = JSON.parse(JSON.stringify(this.computedCart.cartItems));
        for (let i = 0; i < result.length; i += 1) {
          result[i].cikkList.sort((a, b) => a.cikk.cikk_sorrend - b.cikk.cikk_sorrend || a.cikk.cikk_id - b.cikk.cikk_id);
        }
      }
      return result.length > 0 ? result : undefined;
    },
    totalAmount() {
      const amounts = this.sortedCartItems.map((item) => item.cikkList.map((cikk) => cikk.amount).reduce((a, b) => a + b, 0));
      return amounts.reduce((a, b) => a + b, 0);
    },
    isCartEmpty() {
      return this.getCurrentCart === undefined || this.getCurrentCart.cartItems.length === 0;
    },
    summaryValue() {
      return this.$options.filters.toCurrency(this.getCurrentCart.price.grossUnitPrice);
    },
  },
  watch: {
    getCurrentCart() {
      if (this.animateOnChange) {
        this.isAnimating = true;

        setTimeout(() => {
          this.isAnimating = false;
        }, 1000);
      }

      if (this.isInModifyState && this.getCurrentCart !== undefined) {
        this.tempCart = JSON.parse(JSON.stringify(this.getCurrentCart));
      }
    },
  },
  methods: {
    ...mapActions({
      setCartItem: 'cart/setCartItem',
      addCartItem: 'cart/addCartItem',
      removeCartItem: 'cart/removeCartItem',
    }),
    handleQuantityChange(payload) {
      const payloadItem = payload.item;
      if (this.isInModifyState) {
        const groupIndex = this.tempCart.cartItems.findIndex((item) => item.date === payloadItem.date);
        const itemIndex = this.tempCart.cartItems[groupIndex].cikkList.findIndex((item) => item.cikk.cikk_id === payloadItem.cikkId);
        const itemObject = {
          itemId: payloadItem.itemId,
          amount: payloadItem.changedAmount,
          cikkId: payloadItem.cikkId,
          date: payload.date,
        };

        this.tempCart.cartItems[groupIndex].cikkList[itemIndex].amount = payloadItem.changedAmount;

        // if item was in the deletions array, remove from it
        if (this.deletions.includes(payloadItem.itemId)) {
          const itemDelIndex = this.deletions.indexOf(payloadItem.itemId);

          if (itemDelIndex > -1) {
            this.deletions.splice(itemDelIndex, 1);
          }
        }

        const inModListIndex = this.modifications.findIndex((item) => {
          if (item.cikkId === payloadItem.cikkId && item.date === payloadItem.date) {
            return true;
          } return false;
        });

        if (inModListIndex > -1) {
          this.modifications[inModListIndex] = itemObject;
        } else {
          this.modifications.push(itemObject);
        }
      } else {
        this.disableInput = true;
        this.setCartItem({
          cartId: this.getCurrentCart._id,
          requestObj: {
            amount: payloadItem.changedAmount,
            cikkId: payloadItem.cikkId,
            date: payloadItem.date,
          },
        }).then(() => {
          this.disableInput = false;
        }).catch((err) => {
          this.$eventBus.resetCart({ id: payload.id });
          this.disableInput = false;
          this.toast.isVisible = true;
          this.toast.text = err.data.message;
          setTimeout(() => {
            this.toast.isVisible = false;
          }, 1500);
        });
      }
    },
    handleButtonClick() {
      if (this.isInModifyState) {
        // TODO: refactor after API is ready!
        const promises = [];
        this.isButtonLoading = true;

        if (this.deletions.length > 0) {
          for (let i = 0; i < this.deletions.length; i += 1) {
            promises.push(this.removeCartItem({
              cartId: this.getCurrentCart._id,
              cartItemId: this.deletions[i],
            }));
          }
        }

        if (this.modifications.length > 0) {
          for (let i = 0; i < this.modifications.length; i += 1) {
            promises.push(this.setCartItem({
              cartId: this.getCurrentCart._id,
              requestObj: {
                cikkId: this.modifications[i].cikkId,
                amount: this.modifications[i].amount,
                date: this.modifications[i].date,
              },
            }));
          }
        }

        Promise.all(promises).then(() => {
          this.isButtonLoading = false;
          this.deletions = [];
          this.modifications = [];
        });
      } else {
        this.$emit('buttonClicked');
      }
    },
    handleItemDeletion(itemId) {
      const itemIndexInModifications = this.modifications.findIndex((item) => item.itemId === itemId);

      // remove item from modifications list when deleted
      if (itemIndexInModifications > -1) {
        this.modifications.splice(itemIndexInModifications, 1);
      }

      this.deletions.push(itemId);
    },
    handleQuickDeletion(itemId) {
      this.removeCartItem({
        cartId: this.getCurrentCart._id,
        cartItemId: itemId,
      });
    },
  },
};
</script>

<style lang='scss' scoped>
$moduleWidth: 350px;
$emptyStateIconSize: 47px;

.cart-module {
  width: $moduleWidth;
  position: relative;
  transition: background-color 1.5s ease-in-out;

  &.has-error {
    &:before {
      content: '';
      top: 0;
      left: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba($white, 0.8);
      border-radius: $globalBorderRadius;
    }
  }
}

.empty-state {
  width: 100%;
  background-color: $cartModuleEmptyStateBackground;
  color: $white;
  text-align: center;
  border-radius: $globalBorderRadius;
  padding: 15px 28px 28px;

  .title,
  .subtitle {
    display: block;
    font-weight: 500;
  }

  .title {
    font-size: 20px;
    text-transform: uppercase;
    margin: 0 0 15px;
    line-height: 1;
  }

  .subtitle {
    font-size: 12px;
  }

  .icon-wrapper {
    width: $emptyStateIconSize;
    height: $emptyStateIconSize;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
    border-radius: 100%;
    margin: 0 auto 13px;
  }
}

.cart-content {
  background-color: $white;
  width: 100%;
  border-radius: $globalBorderRadius;
  box-shadow: $lightDropdownShadow;
  padding: 17px 10px 20px;

  .cart-title {
    display: block;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 700;
    line-height: 1;
    margin: 0 0 20px;
    text-align: center;
  }

  .summary-row {
    font-weight: 500;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;

    .value {
      color: $primaryBlue;
      font-weight: 700;
    }
  }
}

.cart-items {
  &.scrollable {
    max-height: var(--max-height);
    overflow-y: scroll;
    padding-left: 17px;
    padding-right: 17px;
    padding-bottom: 25px;
    transition: $transitionBase;
  }
}

.cart-item-row {
  margin: 0 0 14px;
}

.cart-footer {
  .button-row {
    margin: 15px 0 0;
  }
}

.error-toast {
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  background-color: $white;
  box-shadow: $lightDropdownShadow;
  border-radius: $globalBorderRadius;
}
</style>

<template>
  <div class='cart-items-group'>
    <div class="cart-item-row">
      <span class="date">{{ date }}</span>
      <div class="cart-item"
        :class="[{ 'has-remove': hasRemove }, { 'deleted-state': hasRemove && localDeleteIds.includes(ticket._id) }]"
        v-for="(ticket, index) in items" :key="index">
        <div class="left-col">
          <span class="zone-name" :style="`color: ${ticket.cikk.facilityColor}`">{{ ticket.cikk.cikkcsop_nev }}</span>
          <span class="ticket-name">{{ ticket.cikk.cikk_nev }}</span>
        </div>
        <div class="right-col" :class="{ 'has-delete-btn': isQuantityDeleteBtnVisible.includes(ticket._id) }">
          <span class="price">{{ ticket.cikk.cikkar.cikkar_bruttoegysegar * ticket.amount | toCurrency }}</span>
          <base-quantity-input
            :id="`${id}-${index}`"
            :defaultValue="ticket.amount"
            :minValue="1"
            :disableInput="disableInput"
            :hasRemoveHandler="true"
            @changed="handleInputChange(`${id}-${index}`, ticket._id, ticket.cikk.cikk_id, ticket.amount, ...arguments)"
            @removeClicked="handleQuickDeleteClick(ticket._id)"
          />
        </div>
        <div v-if="hasRemove" class="delete-col">
          <span class="delete-btn" @click="handleDeleteClick(ticket._id)"><icon-trash /></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import BaseQuantityInput from '@/components/shared/elements/inputs/BaseQuantityInput';

export default {
  name: 'CartItemsGroup',
  props: {
    items: {
      type: Array,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    hasRemove: {
      type: Boolean,
      required: false,
      default: false,
    },
    disableInput: {
      type: Boolean,
      required: false,
      default: false,
    },
    id: {
      type: String,
      required: true,
    },
  },
  components: {
    BaseQuantityInput,
    'icon-trash': () => import('@/components/shared/elements/icons/IconTrash'),
  },
  data: () => ({
    localDeleteIds: [],
    isQuantityDeleteBtnVisible: [],
    changedItem: [],
    isChangeStop: true,
    handlerTimeout: undefined,
    changedInputId: undefined,
  }),
  created() {
  },
  computed: {},
  watch: {
    isChangeStop(val) {
      if (val) {
        this.$emit('quantityChanged', { id: this.changedInputId, item: this.changedItem });
      }
    },
  },
  methods: {
    ...mapActions({
      checkCartLimit: 'cart/checkCartLimit',
    }),
    handleInputChange(id, itemId, cikkId, defaultAmount, changedAmount) {
      this.changedInputId = id;
      this.isChangeStop = false;
      clearTimeout(this.handlerTimeout);

      if (this.localDeleteIds.includes(itemId)) {
        const itemIndex = this.localDeleteIds.indexOf(itemId);

        if (itemIndex > -1) {
          this.localDeleteIds.splice(itemIndex, 1);
        }
      }

      if (changedAmount > 0 && this.isQuantityDeleteBtnVisible.includes(itemId)) {
        this.isQuantityDeleteBtnVisible.splice(this.localDeleteIds.indexOf(itemId), 1);
      }

      if (defaultAmount !== changedAmount) {
        if (changedAmount > 0) {
          this.changedItem = {
            itemId, cikkId, changedAmount, date: this.date,
          };
          this.handlerTimeout = setTimeout(() => {
            this.isChangeStop = true;
          }, 500);
        } else if (changedAmount === 0) {
          this.isQuantityDeleteBtnVisible.push(itemId);
        }
      }
    },
    handleDeleteClick(itemId) {
      this.localDeleteIds.push(itemId);
      this.$emit('deleteClicked', itemId);
    },
    handleQuickDeleteClick(itemId) {
      this.isChangeStop = false;
      clearTimeout(this.handlerTimeout);

      this.isQuantityDeleteBtnVisible.splice(this.localDeleteIds.indexOf(itemId), 1);
      this.$emit('quickDeleteClicked', itemId);
    },
  },
};
</script>

<style lang='scss' scoped>
.date {
    font-weight: 700;
    font-size: 15px;
    line-height: 1;
    margin: 0 0 7px;
    padding-left: 12px;
    display: block;
  }

.cart-item {
  border-radius: $globalBorderRadius;
  box-shadow: $cartItemRowShadow;
  background-color: $white;
  padding: 10px 13px;
  display: grid;
  grid-template-columns: 0.9fr auto;
  font-size: 15px;
  margin: 0 0 7px;
  transition: $transitionBase;

  &.deleted-state {
    opacity: 0.5;
  }

  &.has-remove {
    grid-template-columns: 0.9fr auto auto;
  }

  .zone-name {
    font-weight: 700;
    text-transform: uppercase;
    display: block;
  }

  .right-col {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
  }

  .remove-btn {
    grid-area: 1 / 2 / 3 / 3;
    display: flex;
    align-items: center;

    .icon-generate {
      cursor: pointer;

      &:hover {
        &::v-deep {
          svg path {
            fill: $primaryOrangeLight;
          }
        }
      }

      &::v-deep {
        svg path {
          transition: $transitionBase;
        }
      }
    }
  }

  .ticket-name {
    font-weight: 500;
    line-height: 1.2;
    display: inline-block;
  }

  .price {
    display: block;
    color: $primaryBlue;
    font-weight: 700;
    width: 100%;
    text-align: right;
  }
}

.delete-col {
  display: flex;
  justify-content: center;
  align-items: center;

  .delete-btn {
    margin-left: 20px;
    cursor: pointer;

    &:hover {
      &::v-deep {
        svg path {
          fill: $primaryOrangeLight;
        }
      }
    }

    &::v-deep {
      svg path {
        transition: $transitionBase;
      }
    }
  }
}
</style>
